<template>
  <div>
    <div class="air__utils__heading">
      <h5>Administración de Campos de Check List</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="openListModal()" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- CheckLists -->
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="CheckLists.data"
      :columns="CheckLists.columns"
      :loading="CheckLists.loading"
      :row-key="record => record.code"
      :pagination="CheckLists.pagination"
      @change="getCheckLists"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <template slot="myCustomColumns" slot-scope="text, record">
        <slot name="details" :record="record"/>
      </template>
      <template slot="action" slot-scope="data">
        <responsive-button variant="primary" pill
                           size="sm" responsive="md"
                           text="Editar" icon="plus"
                           @ClickEvent="openListModal(data)"
        />
        <responsive-button variant="secondary" pill
                           size="sm" responsive="md"
                           text="Nuevo Campo" icon="plus"
                           @ClickEvent="OpenModal('list', data)"
        />
      </template>

      <!-- CheckFields-->
      <a-table class="bg-gray-3" slot="expandedRowRender" slot-scope="lists"
        :columns="columns"
        :data-source="getCheckFields(lists.checkFields)"  :pagination="false">
        <div slot="properties" slot-scope="data">
          <ol>
            <li v-for="value in data" :key="value.key">
              {{value.value}}
              <b-icon v-if="value.boolean" icon="check-circle-fill" variant="primary" />
              <b-icon v-else icon="x" variant="warning" />
            </li>
          </ol>
        </div>
        <a slot="action" slot-scope="data">
          <responsive-button variant="secondary" pill
                             size="sm" responsive="md"
                             text="Editar" icon="pencil-square"
                             @ClickEvent="OpenModal('field', data)"
          />
        </a>
      </a-table>
      <!-- CheckFields-->
    </a-table>
    <!-- CheckLists -->
    <b-modal title="Detalle de Campo" ref="modal-form" size="xl">
      <b-container fluid>
          <!-- Form with Model -->
          <a-form-model ref="rule-Form" layout="horizontal" :model="form" :rules="rules">
            <a-row align="middle" justify="center" class="mb-4">
              <div class="card">
                <div class="card-body">
                  <a-form-model-item ref="name" label="Nombre del Campo" prop="name" >
                    <a-input v-model.trim="form.name"/>
                  </a-form-model-item>
                </div>
              </div>
            </a-row>
            <a-row align="middle" justify="center" class="mb-4">
              <div class="card">
                <div class="card-body">
                  <a-form-model-item
                    v-for="(tag, index) in form.properties"
                    :key="tag.key"
                    :label="index === 0 ? 'Característica' : ''"
                    ref="properties"
                    :prop="'properties.' + index + '.value'"
                    :rules="{ required: true, message: 'La característica no puede estar vacía', trigger: 'blur'}"
                  >
                    <a-col :sm="{span:19}" :md="{span:16}">
                      <a-input v-model="tag.value" placeholder="Característica"/>
                    </a-col>
                    <a-col :sm="{span:4, offset:1}" :md="{span:7, offset:1}">
                      <a-switch v-model="tag.boolean" default-checked />
                    </a-col>

                    <responsive-button text="" icon="dash" responsive="md" size="sm"
                                       @ClickEvent="removeDomain(tag)" pill/>
                  </a-form-model-item>
                  <a-form-model-item>
                    <responsive-button text="Agregar Característica" icon="plus" responsive="md" @ClickEvent="addDomain" pill/>
                  </a-form-model-item>
                </div>
              </div>
            </a-row>
            <my-spinner :load="load"/>
            <div class="row justify-content-center" v-show="!load">
              <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
              <responsive-button v-if="checkFieldId !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
            </div>
          </a-form-model>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!--CheclList Modal-->
    <b-modal title="Detalle de Check List" ref="modal-formList" size="xl">
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model ref="rule-FormList" layout="horizontal" :model="formList" :rules="rulesList">
          <a-row align="middle" justify="center" class="mb-4">
            <div class="card">
              <div class="card-body">
                <a-form-model-item ref="name" label="Nombre del Check List" prop="name" >
                  <a-input v-model.trim="formList.name"/>
                </a-form-model-item>
              </div>
            </div>
          </a-row>
          <my-spinner :load="load"/>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmitList"/>
            <responsive-button v-if="listId !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDeleteList"/>
          </div>
        </a-form-model>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="closeModalList"/>
      </template>
    </b-modal>
    <!--CheclList Modal-->
  </div>
</template>

<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { Modal, notification } from 'ant-design-vue'
export default {
  name: 'checkDetail',
  mixins: [getGeneralMixin],
  data() {
    return {
      check: this.$route.params.check,
      resource: 'checkFields',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'initial_settings',
          breadcrumbName: 'Configuraciones',
          aIcon: '',
        },
        {
          breadcrumbName: 'Checklist de Planeación de Servicios',
          aIcon: '',
          path: '/configuraciones/service_plannings_checklist',
        },
        {
          breadcrumbName: 'Checklist de Planeación de Servicios',
          aIcon: '',
        },
      ],
      // <!-- CheckLists -->
      searchText: '',
      CheckLists: {
        data: [],
        columns: [
          {
            title: 'Nombre de Check List',
            dataIndex: 'name',
            key: 'name',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          }, {
            title: 'Acciones',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
      },
      // <!-- CheckLists -->
      // <!-- CheckFields -->
      columns: [
        {
          title: 'Campo',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {
            customRender: 'name',
          },
        },
        {
          title: 'Características',
          dataIndex: 'properties',
          key: 'properties',
          scopedSlots: {
            customRender: 'properties',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          class: 'text-center',
          key: 'x',
          scopedSlots: {
            customRender: 'action',
          },
        }],
      // <!-- CheckFields -->
      // <!-- CheckFields Form -->
      form: {
        name: '',
        properties: [],
      },
      rules: {
        name: [
          { required: true, message: 'El campo Nombre del Campo es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campoNombre del Campo debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre del Campo debe ser de tipo texto.', trigger: 'blur' },
        ],
      },
      checkFieldId: '0',
      load: false,
      checkListId: '',
      // <!-- CheckFields Form -->
      listId: '0',
      formList: {
        name: '',
      },
      rulesList: {
        name: [
          { required: true, message: 'El campo Nombre del Campo es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campoNombre del Campo debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre del Campo debe ser de tipo texto.', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    async getCheckLists(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.CheckLists.pagination.current}` : `${this.CheckLists.currentPageValue}`,
        'page[size]': `${this.CheckLists.perPageValue}`,
        'filter[checks]': this.check,
        include: 'checkFields.checkLists',
      }
      await this.getGeneralAntTable(paginationEvent, 'checkLists', params, this.CheckLists)
    },
    // CRUD CheckFields
    getCheckFields(checkFields) {
      const arrayFields = Object.keys(checkFields).map(key => {
        return checkFields[key]
      })
      return arrayFields
    },
    removeDomain(item) {
      const index = this.form.properties.indexOf(item)
      if (index !== -1) {
        this.form.properties.splice(index, 1)
      }
    },
    addDomain() {
      this.form.properties.push({
        value: '',
        boolean: true,
        key: Date.now(),
      })
    },
    OpenModal(from, data = {}) {
      this.$refs['modal-form'].show()
      if (from === 'list') {
        //  Store case
        this.checkListId = data.jsonApi.id
        this.checkFieldId = '0'
        this.form.name = ''
        this.form.properties = []
      } else if (from === 'field') {
        // Update case
        this.checkListId = data.checkLists.jsonApi.id
        this.checkFieldId = data.jsonApi.id
        this.form.name = data.name
        this.form.properties = data.properties
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.checkListId = '0'
      this.checkFieldId = '0'
      this.resetForm()
    },
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let action = 'post'
          // Set relationships
          const relationships = {
            checkLists: {
              data: { id: this.checkListId, type: 'checkLists' },
            },
          }
          const formObject = {
            name: this.form.name,
            properties: this.form.properties,
            jsonApi: {
              type: this.resource,
              relationships: relationships,
            },
          }
          if (this.checkFieldId !== '0') {
            // Update Object
            action = 'patch'
            formObject.jsonApi.id = this.checkFieldId
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getCheckLists()
              this.load = false
              this.CloseModal()
            })
            .catch(() => { this.load = false })
          // Envio del Objecto
        } else return false
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.checkFieldId,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getCheckLists()
              me.CloseModal()
              me.load = false
            })
            .catch(() => {
              me.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
    // CRUD CheckList
    openListModal(data = {}) {
      this.$refs['modal-formList'].show()
      if (Object.keys(data).length === 0) {
        this.formList.name = ''
        this.listId = '0'
      } else {
        this.formList.name = data.name
        this.listId = data.jsonApi.id
      }
    },
    closeModalList() {
      this.$refs['modal-formList'].hide()
      this.formList.name = ''
      this.listId = '0'
    },
    onSubmitList() {
      this.$refs['rule-FormList'].validate(valid => {
        if (valid) {
          let action = 'post'
          // Set relationships
          const relationships = {
            checks: {
              data: { id: this.check, type: 'checks' },
            },
          }
          const formObject = {
            name: this.formList.name,
            jsonApi: {
              type: 'checkLists',
              relationships: relationships,
            },
          }
          if (this.listId !== '0') {
            // Update Object
            action = 'patch'
            formObject.jsonApi.id = this.listId
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getCheckLists()
              this.load = false
              this.closeModalList()
            })
            .catch(() => { this.load = false })
          // Envio del Objecto
        } else return false
      })
    },
    onDeleteList() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: 'checkLists',
              id: me.listId,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getCheckLists()
              me.closeModalList()
              me.load = false
            })
            .catch(() => {
              me.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
  },
  async mounted() {
    await this.getCheckLists()
  },
}
</script>

<style scoped>

</style>
